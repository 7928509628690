import {
  ALL_ACCOUNTS,
  DELETE_ACCOUNT,
  UPADTE_ALL_ACCOUNT,
  GET_ACCOUNT,
  UPADTE_ACCOUNT
} from 'redux/actions/AccountActions';
import accountService from 'services/accountService';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { message } from 'antd';
function* getAllAccounntsAction() {
  //const projects = yield projectService.getAllProjects();
  const { data } = yield accountService.getAllAccounnts();

  yield put({
    type: UPADTE_ALL_ACCOUNT,
    payload: data
  });
}

function* getAccountAcction(action) {
  //const projects = yield projectService.getAllProjects();
  const { data } = yield accountService.getAccount(action.payload);

  yield put({
    type: UPADTE_ACCOUNT,
    payload: data
  });
}

function* deleteAccountAction(action) {
  const data = yield accountService.deleteAccount(action.payload);
  if (data.status === 204) {
    message.success('Account delete successfully');
    const { finalData } = yield accountService.getAllAccounnts();
    yield put({
      type: UPADTE_ALL_ACCOUNT,
      payload: finalData
    });
  }
}

export function* fetchAllAccounts() {
  yield takeLatest(ALL_ACCOUNTS, getAllAccounntsAction);
}

export function* deleteAccount() {
  yield takeLatest(DELETE_ACCOUNT, deleteAccountAction);
}

export function* getAccount() {
  yield takeLatest(GET_ACCOUNT, getAccountAcction);
}
