export const UPDATE_ADVERTISER = 'UPDATE_ADVERTISER';
export const ALL_ADVERTISER = 'ALL_ADVERTISER';
export const UPADTE_ALL_ADVERTISER = 'UPADTE_ALL_ADVERTISER';
export const NEW_ADVERTISER = 'NEW_ADVERTISER';
export const DELETE_ADVERTISER = 'DELETE_ADVERTISER';
export const GET_ADVERTISER = 'GET_ADVERTISER';

export function getAllAdvertiser(payload) {
  return {
    payload: payload,
    type: ALL_ADVERTISER
  };
}

export function newAdvertiser() {
  return {
    type: NEW_ADVERTISER
  };
}

export function updateAdvertiser(action) {
  return {
    type: UPDATE_ADVERTISER,
    payload: action
  };
}

export function deleteAdvertiser(action) {
  return {
    type: DELETE_ADVERTISER,
    payload: action
  };
}

export function getAdvertiser(payload) {
  return {
    type: GET_ADVERTISER,
    payload: payload
  };
}
