import React, { useEffect } from 'react';
import { Button } from 'antd';
import { APP_NAME } from 'configs/AppConfig';
import { Link } from 'react-router-dom';
import Flex from 'components/shared-components/Flex';
import { useSelector } from 'react-redux';

const LoginKeyclock = props => {
  const theme = useSelector(state => state.theme.currentTheme);
  const { login } = props;
  useEffect(() => {
    login();
  });
  return (
    <div className={`h-100 ${theme === 'light' ? 'bg-white' : ''}`}>
      <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
        <div className="container">
          <div className="text-center mb-5">
            <a href="#" onClick={event => login(event)}>
              <Button type="primary">Please wait while logging</Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginKeyclock;
