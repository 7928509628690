import {
  ALL_FORMS,
  DELETE_FORM,
  UPADTE_ALL_FORMS
} from 'redux/actions/FormActions';
import formService from 'services/formService';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { message } from 'antd';
function* getAllFormsActions(action) {
  //const projects = yield projectService.getAllProjects();

  const { data } = yield formService.getAllForms(action.payload.accountId);

  yield put({
    type: UPADTE_ALL_FORMS,
    payload: data
  });
}

function* deleteFormAction(action) {
  const data = yield formService.deleteForm(action.payload);
  if (data.status === 204) {
    message.success('Account delete successfully');
    const { finalData } = yield formService.getAllForms();
    yield put({
      type: UPADTE_ALL_FORMS,
      payload: finalData
    });
  }
}

export function* fetchAllForms() {
  yield takeLatest(ALL_FORMS, getAllFormsActions);
}

export function* deleteForms() {
  yield takeLatest(DELETE_FORM, deleteFormAction);
}
