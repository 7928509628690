export const UPDATE_CAMP = 'UPDATE_CAMP';
export const ALL_CAMPS = 'ALL_CAMPS';
export const UPDATE_ALL_CAMPS = 'UPDATE_ALL_CAMPS';
export const NEW_CAMP = 'NEW_CAMP';
export const DELETE_CAMP = 'DELETE_CAMP';
export const ADD_FIELDS = 'ADD_FIELDS';
export const ADD_SUPPLIER_FORM_DATA = 'ADD_SUPPLIER_FORM_DATA';
export const GET_CAMP = 'GET_CAMP';

export function addFields(payload) {
  return {
    type: ADD_FIELDS,
    payload: payload
  };
}

export function getAllCamps(payload) {
  return {
    type: ALL_CAMPS,
    payload: payload
  };
}

export function newCamp() {
  return {
    type: NEW_CAMP
  };
}

export function updateCamp(action) {
  return {
    type: UPDATE_CAMP,
    payload: action
  };
}

export function deleteCamp(action) {
  return {
    type: DELETE_CAMP,
    payload: action
  };
}

export function addSupplierFormData(action) {
  return {
    type: ADD_SUPPLIER_FORM_DATA,
    payload: action
  };
}

export function getCamp(action) {
  return {
    type: GET_CAMP,
    payload: action
  };
}
