import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { useAuthContext } from 'auth/useAuthContext';

const PublicRoute = () => {
  const { isAuthenticated } = useAuthContext();

  const { token } = useSelector(state => state.auth);

  return isAuthenticated ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet />;
};

export default PublicRoute;
