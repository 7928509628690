import {
  UPDATE_PUBLISHER,
  UPADTE_ALL_PUBLISHER,
  NEW_PUBLISHER,
  UPADTE_USER
} from '../actions/publisherAction';

const initialState = {
  formData: {},
  publishers: [],
  mode: 'ADD'
};

const publisherReducer = (state = initialState, action) => {
  console.log(action.type);
  if (action.type === UPDATE_PUBLISHER) {
    return Object.assign({}, state, {
      formData: action.payload
    });
  }

  if (action.type === UPADTE_ALL_PUBLISHER) {
    return Object.assign({}, state, {
      publishers: action.payload
    });
  }
  if (action.type === NEW_PUBLISHER) {
    return Object.assign({}, state, {
      formData: {}
    });
  }
  return state;
};

export default publisherReducer;
