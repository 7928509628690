import React, { lazy, Suspense, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import Loading from 'components/shared-components/Loading';
import { lightTheme, darkTheme } from 'configs/ThemeConfig';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import Routes from 'routes';
import { useAuth } from 'react-oidc-context';
import LoginKeyclock from 'views/auth-views/authentication/login-main';
import { useAuthContext } from 'auth/useAuthContext';
import { LOGIN } from 'redux/actions/LoginAction';
import { useNavigate } from 'react-router-dom';
const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));
const ChangePasswordLayout = lazy(() => import('./ChangePasswordLayout'));
const FormLayout = lazy(() => import('../views/auth-views/forms'));
const Layouts = () => {
  const { isAuthenticated, isTempPassword, isInitialized } = useAuthContext();

  const url = window.location.href;

  const blankLayout = useSelector(state => state.theme.blankLayout);

  let Layout = isAuthenticated ? AppLayout : AuthLayout;
  const navigate = useNavigate();

  if (url.indexOf('/forms/slug/') != -1) {
    Layout = FormLayout;
  } else if (isAuthenticated && isTempPassword) {
    Layout = ChangePasswordLayout;
  }

  const locale = useSelector(state => state.theme.locale);

  const direction = useSelector(state => state.theme.direction);

  const currentTheme = useSelector(state => state.theme.currentTheme);

  const currentAppLocale = resources[locale];

  useBodyClass(`dir-${direction}`);

  const themeConfig =
    currentTheme === 'light' ? { ...lightTheme } : { ...darkTheme };

  if (!isInitialized) {
    return <h4>Loading</h4>;
  }

  return (
    <ConfigProvider
      theme={themeConfig}
      direction={direction}
      locale={currentAppLocale.antd}
    >
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);
