export const LOGIN = 'LOGIN';
export const UPDATE_LOGIN_INFO = 'UPDATE_LOGIN_INFO';

export function updateLoginInfo(action) {
  return {
    type: UPDATE_LOGIN_INFO,
    payload: action
  };
}

export function login(action) {
  return {
    type: LOGIN,
    payload: action
  };
}
