import {
  UPDATE_ADVERTISER,
  UPADTE_ALL_ADVERTISER,
  NEW_ADVERTISER,
  UPADTE_USER
} from '../actions/AdvertiserAction';

const initialState = {
  formData: {},
  advertisers: [],
  mode: 'ADD'
};

const advertiserReducer = (state = initialState, action) => {
  console.log(action.type);
  if (action.type === UPDATE_ADVERTISER) {
    return Object.assign({}, state, {
      formData: action.payload
    });
  }

  if (action.type === UPADTE_ALL_ADVERTISER) {
    return Object.assign({}, state, {
      advertisers: action.payload
    });
  }
  if (action.type === NEW_ADVERTISER) {
    return Object.assign({}, state, {
      formData: {}
    });
  }
  return state;
};

export default advertiserReducer;
