export const UPADTE_LANDING_PAGE = 'UPADTE_LANDING_PAGE';
export const ALL_LANDING_PAGES = 'ALL_LANDING_PAGES';
export const UPADTE_ALL_LANDING_PAGES = 'UPADTE_ALL_LANDING_PAGES';
export const NEW_LANDING_PAGE = 'NEW_LANDING_PAGE';
export const DELETE_LANDING_PAGE = 'DELETE_LANDING_PAGE';
export const GET_LANDING_PAGE = 'GET_LANDING_PAGE';
export const UPDATE_LANDING_PAGE = 'UPDATE_LANDING_PAGE';
export function getAllLandingPages(action) {
  return {
    type: ALL_LANDING_PAGES,
    payload: action
  };
}

export function getLandingPage(action) {
  return {
    type: GET_LANDING_PAGE,
    payload: action
  };
}

export function newLandingPage() {
  return {
    type: NEW_LANDING_PAGE
  };
}

export function updateLandingPage(action) {
  return {
    type: UPADTE_LANDING_PAGE,
    payload: action
  };
}

export function deleteLandingPage(action) {
  return {
    type: DELETE_LANDING_PAGE,
    payload: action
  };
}
