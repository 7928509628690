import axiosInstance from 'utils/axios';

const baseUrl = '/convey_ai/api/account';

const accountService = {};

accountService.getPost = function (params) {
  return fetch({
    url: '/posts/1',
    method: 'get',
    params
  });
};

accountService.getAllAccounnts = function () {
  return axiosInstance.get(`${baseUrl}/`);
};

accountService.deleteAccount = function (id) {
  return axiosInstance.delete(`${baseUrl}/${id}`);
};

accountService.saveAccount = function (data) {
  if (data.id) {
    return axiosInstance.put(`${baseUrl}/${data.id}`, data);
  } else {
    return axiosInstance.post(`${baseUrl}/`, data);
  }
};

accountService.getAccount = function (id) {
  return axiosInstance.get(`${baseUrl}/${id}`);
};

export default accountService;
