import axiosInstance from 'utils/axios';

const baseUrl = '/convey_ai/v1/forms';

const formService = {};

formService.getPost = function (params) {
  return fetch({
    url: '/posts/1',
    method: 'get',
    params
  });
};

formService.getAllForms = function (id) {
  return axiosInstance.get(`${baseUrl}/${id}`);
};

formService.deleteForm = function (id) {
  return axiosInstance.delete(`${baseUrl}/${id}`);
};

formService.save = function (data) {
  if (data.id) {
    return axiosInstance.put(`${baseUrl}/${data.id}`, data);
  } else {
    return axiosInstance.post(`${baseUrl}/`, data);
  }
};

formService.getFormById = (acctId, formId) => {
  return axiosInstance.get(`${baseUrl}/${acctId}/${formId}`);
}

export default formService;
