import {
  ALL_ADVERTISER,
  DELETE_ADVERTISER,
  GET_ADVERTISER,
  UPADTE_ALL_ADVERTISER,
  UPDATE_ADVERTISER
} from 'redux/actions/AdvertiserAction';
import advertiserService from 'services/advertiserService';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { message } from 'antd';
function* getAllAdvertiserAction(action) {
  //const projects = yield projectService.getAllProjects();
  let finalData = {};

  const { data } = yield advertiserService.getByAccount(
    action.payload.accountId
  );
  finalData = data;

  yield put({
    type: UPADTE_ALL_ADVERTISER,
    payload: finalData
  });
}

function* deleteAdvertiserAction(action) {
  const data = yield advertiserService.deleteAdvertiser(
    action.payload.id,
    action.payload.accountId
  );
  if (data.status === 204) {
    message.success('Advertiser deleted successfully');
    const { finalData } = yield advertiserService.getByAccount(
      action.payload.accountId
    );
    yield put({
      type: UPADTE_ALL_ADVERTISER,
      payload: finalData
    });
  }
}

function* getAdvertiserAction(action) {
  //const projects = yield projectService.getAllProjects();
  let finalData = {};

  const { data } = yield advertiserService.getAdvertiser(
    action.payload.accountId,
    action.payload.id
  );
  finalData = data;
  if (finalData.autoSignContracts) {
    finalData['autoSignContracts'] = finalData.autoSignContracts ? 'T' : 'F';
  }
  yield put({
    type: UPDATE_ADVERTISER,
    payload: finalData
  });
}

export function* fetchAllAdvertiser() {
  yield takeLatest(ALL_ADVERTISER, getAllAdvertiserAction);
}

export function* deleteAdvertiser() {
  yield takeLatest(DELETE_ADVERTISER, deleteAdvertiserAction);
}

export function* getAdvertiser() {
  yield takeLatest(GET_ADVERTISER, getAdvertiserAction);
}
