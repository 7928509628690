import axiosInstance from 'utils/axios';

const baseUrl = '/convey_ai/v1/publishers';

const publisherService = {};

publisherService.getByAccount = function (accountID) {
  return axiosInstance.get(`${baseUrl}/${accountID}`);
};

publisherService.getAll = function () {
  return axiosInstance.get(`${baseUrl}/`);
};

publisherService.get = function (accountID, advertiserID) {
  return axiosInstance.get(`${baseUrl}/${accountID}/${advertiserID}`);
};

publisherService.deletePublisher = function (advertiserID, accountID) {
  return axiosInstance.delete(`${baseUrl}/${accountID}/${advertiserID}`);
};

publisherService.getPublisher = function (accountId, id) {
  return axiosInstance.get(`${baseUrl}/${accountId}/${id}`);
};

publisherService.save = function (data) {
  if (data.id) {
    return axiosInstance.put(`${baseUrl}/${data.id}`, data);
  } else {
    return axiosInstance.post(`${baseUrl}/`, data);
  }
};

export default publisherService;
