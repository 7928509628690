export const UPDATE_PUBLISHER = 'UPDATE_PUBLISHER';
export const ALL_PUBLISHER = 'ALL_PUBLISHER';
export const UPADTE_ALL_PUBLISHER = 'UPADTE_ALL_PUBLISHER';
export const NEW_PUBLISHER = 'NEW_PUBLISHER';
export const DELETE_PUBLISHER = 'DELETE_PUBLISHER';
export const GET_PUBLISHER = 'GET_PUBLISHER';

export function getAllPublisher(payload) {
  return {
    payload: payload,
    type: ALL_PUBLISHER
  };
}

export function newPublisher(payload) {
  return {
    type: NEW_PUBLISHER,
    payload: payload
  };
}

export function updatePublisher(action) {
  return {
    type: UPDATE_PUBLISHER,
    payload: action
  };
}

export function deletePublisher(action) {
  return {
    type: DELETE_PUBLISHER,
    payload: action
  };
}

export function getPublisher(payload) {
  return {
    type: GET_PUBLISHER,
    payload: payload
  };
}
