import {
  UPADTE_ACCOUNT,
  UPADTE_ALL_ACCOUNT,
  NEW_ACCOUNT
} from '../actions/AccountActions';

const initialState = {
  formData: {},
  accounts: [],
  mode: 'ADD'
};

const accountReducer = (state = initialState, action) => {
  console.log(action.type);
  if (action.type === UPADTE_ACCOUNT) {
    return Object.assign({}, state, {
      formData: action.payload
    });
  }
  if (action.type === UPADTE_ALL_ACCOUNT) {
    return Object.assign({}, state, {
      accounts: action.payload
    });
  }
  if (action.type === NEW_ACCOUNT) {
    return Object.assign({}, state, {
      formData: {}
    });
  }
  return state;
};

export default accountReducer;
