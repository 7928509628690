import { ROLES } from 'constants/UserRoleConstant';
import _ from 'lodash';
import axiosInstance from 'utils/axios';

const baseUrl = '/convey_ai/v1/advertisers';

const advertiserService = {};

advertiserService.getAllAdvertisers = (accountId, advertiserId, role, email) => {
  if (_.includes(role, ROLES.ROLE_MANAGER) || _.isEqual(role, ROLES.ROLE_SYSTEM_ADMIN)) {
    return axiosInstance.get(`${baseUrl}/${accountId}`);
  } else if (_.includes(role, ROLES.ROLE_ADVERTISER)) {
    return axiosInstance.get(`${baseUrl}/${accountId}/advertiser/${advertiserId}`);
  } else if (_.includes(role, ROLES.ROLE_PUBLISHER)) {
    return axiosInstance.get(`${baseUrl}/${accountId}/publisher/${email}`);
  }
}

advertiserService.getByAccount = function (accountID) {
  return axiosInstance.get(`${baseUrl}/${accountID}`);
};

advertiserService.getAll = function () {
  return axiosInstance.get(`${baseUrl}/`);
};


advertiserService.getAllByAdvertiserId = function (acctId, advId) {
  return axiosInstance.get(`${baseUrl}/${acctId}/advertiser/${advId}`);
};


advertiserService.get = function (accountID, advertiserID) {
  return axiosInstance.get(`${baseUrl}/${accountID}/${advertiserID}`);
};

advertiserService.deleteAdvertiser = function (advertiserID, accountID) {
  return axiosInstance.delete(`${baseUrl}/${accountID}/${advertiserID}`);
};

advertiserService.getAdvertiser = function (accountId, id) {
  return axiosInstance.get(`${baseUrl}/${accountId}/${id}`);
};

advertiserService.loadJobs = function () {
  return axiosInstance.get(`/convey_ai/v1/jobs/`);
};

advertiserService.save = function (data) {
  if (data.id) {
    return axiosInstance.put(`${baseUrl}/${data.id}`, data);
  } else {
    return axiosInstance.post(`${baseUrl}/`, data);
  }
};

export default advertiserService;
