import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  PUBLIC_PREFIX_PATH
} from 'configs/AppConfig';

import React from 'react';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import('views/auth-views/authentication/login'))
  },
  {
    key: 'login-1',
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/login-1')
    )
  },
  {
    key: 'login-2',
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/login-2')
    )
  },
  {
    key: 'register-1',
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/register-1')
    )
  },
  {
    key: 'register-2',
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/register-2')
    )
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/forgot-password')
    )
  },
  {
    key: 'change-password',
    path: `${AUTH_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/forgot-password')
    )
  },
  {
    key: 'error-page-1',
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import('views/auth-views/errors/error-page-1'))
  },
  {
    key: 'error-page-2',
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import('views/auth-views/errors/error-page-2'))
  },
  {
    key: 'forms-slug',
    path: `${AUTH_PREFIX_PATH}/forms/:id`,
    component: React.lazy(() => import('views/auth-views/forms'))
  },
  {
    key: 'contract',
    path: `${PUBLIC_PREFIX_PATH}/publisher/signed-contracts`,
    component: React.lazy(() => import('views/auth-views/contracts'))
  }
];

export const protectedRoutes = {
  ROLE_SYSTEM_ADMIN: [
    {
      key: 'sidenav.settings.accounts',
      path: `${APP_PREFIX_PATH}/settings/accounts`,
      component: React.lazy(() =>
        import('views/app-views/accounts/accouts-list')
      )
    },
    {
      key: 'sidenav.settings.accounts',
      path: `${APP_PREFIX_PATH}/settings/accounts/add`,
      component: React.lazy(() =>
        import('views/app-views/accounts/accounts-add')
      )
    },
    {
      key: 'sidenav.settings.accounts',
      path: `${APP_PREFIX_PATH}/settings/accounts/edit/:id`,
      component: React.lazy(() =>
        import('views/app-views/accounts/accounts-add')
      )
    },
    {
      key: 'sidenav.settings.users',
      path: `${APP_PREFIX_PATH}/settings/users`,
      component: React.lazy(() => import('views/app-views/users/users-list'))
    },
    {
      key: 'sidenav.settings.users',
      path: `${APP_PREFIX_PATH}/settings/users/add`,
      component: React.lazy(() => import('views/app-views/users/users-add'))
    },
    {
      key: 'sidenav.settings.users',
      path: `${APP_PREFIX_PATH}/settings/users/edit/:id`,
      component: React.lazy(() => import('views/app-views/users/users-add'))
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser`,
      component: React.lazy(() =>
        import('views/app-views/advertiser/advertiser-views')
      )
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser/add`,
      component: React.lazy(() =>
        import('views/app-views/advertiser/advertiser-add')
      )
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser/edit/:id`,
      component: React.lazy(() =>
        import('views/app-views/advertiser/advertiser-add')
      )
    },
    {
      key: 'sidenav.settings.publisher',
      path: `${APP_PREFIX_PATH}/publisher`,
      component: React.lazy(() =>
        import('views/app-views/publisher/publisher-views')
      )
    },
    {
      key: 'sidenav.settings.publisher',
      path: `${APP_PREFIX_PATH}/publisher/add`,
      component: React.lazy(() =>
        import('views/app-views/publisher/publisher-add')
      )
    },
    {
      key: 'sidenav.settings.publisher',
      path: `${APP_PREFIX_PATH}/publisher/edit/:id`,
      component: React.lazy(() =>
        import('views/app-views/publisher/publisher-add')
      )
    },
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-list')
      )
    },
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/add`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-add')
      )
    },
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/edit/:id`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-add')
      )
    },

    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/edit/:id/supplier/:supplierId`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-supplier')
      )
    },
    {
      key: 'sidenav.settings.forms',
      path: `${APP_PREFIX_PATH}/forms`,
      component: React.lazy(() => import('views/app-views/forms/forms-list'))
    },
    {
      key: 'sidenav.settings.forms',
      path: `${APP_PREFIX_PATH}/forms/add`,
      component: React.lazy(() => import('views/app-views/forms/forms-add'))
    },
    {
      key: 'sidenav.settings.forms',
      path: `${APP_PREFIX_PATH}/forms/edit/:id`,
      component: React.lazy(() => import('views/app-views/forms/forms-add'))
    },
    {
      key: 'sidenav.settings.profile',
      path: `${APP_PREFIX_PATH}/settings/profile/:id`,
      component: React.lazy(() =>
        import('views/app-views/profile/profile-views')
      )
    },
    {
      key: 'sidenav.settings.dashboards',
      path: `${APP_PREFIX_PATH}/dashboard`,
      component: React.lazy(() => import('views/app-views/dashboards/home'))
    },
    {
      key: 'sidenav.settings.dashboards',
      path: `/`,
      component: React.lazy(() => import('views/app-views/dashboards/home'))
    },
    {
      key: 'sidenav.settings.reports',
      path: `${APP_PREFIX_PATH}/reports`,
      component: React.lazy(() => import('views/app-views/reports/Reports'))
    },
    {
      key: 'sidenav.settings.reports.edit',
      path: `${APP_PREFIX_PATH}/reports/edit/:reportId`,
      component: React.lazy(() => import('views/app-views/reports/CreateReport'))
    },
    {
      key: 'sidenav.settings.reports.add',
      path: `${APP_PREFIX_PATH}/reports/add`,
      component: React.lazy(() => import('views/app-views/reports/CreateReport'))
    },
  ],
  ROLE_ADVERTISER: [
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/:advertiserId/:id/overview`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campgins-overview')
      )
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser/:id/list-campaigns`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-list')
      )
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser`,
      component: React.lazy(() =>
        import('views/app-views/advertiser/advertiser-views')
      )
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser/add`,
      component: React.lazy(() =>
        import('views/app-views/advertiser/advertiser-add')
      )
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser/edit/:id`,
      component: React.lazy(() =>
        import('views/app-views/advertiser/advertiser-add')
      )
    },
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-list')
      )
    },
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/add`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-add')
      )
    },
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/edit/:id`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-add')
      )
    },

    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/edit/:id/supplier/:supplierId`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-supplier')
      )
    },
    {
      key: 'sidenav.settings.profile',
      path: `${APP_PREFIX_PATH}/settings/profile/:id`,
      component: React.lazy(() =>
        import('views/app-views/profile/profile-views')
      )
    },
    {
      key: 'sidenav.settings.dashboards',
      path: `${APP_PREFIX_PATH}/dashboard`,
      component: React.lazy(() => import('views/app-views/dashboards/home'))
    },
    {
      key: 'sidenav.settings.dashboards',
      path: `/`,
      component: React.lazy(() => import('views/app-views/dashboards/home'))
    },
    {
      key: 'sidenav.settings.reports',
      path: `${APP_PREFIX_PATH}/reports`,
      component: React.lazy(() => import('views/app-views/reports/Reports'))
    },
    {
      key: 'sidenav.settings.reports.edit',
      path: `${APP_PREFIX_PATH}/reports/edit/:reportId`,
      component: React.lazy(() => import('views/app-views/reports/CreateReport'))
    },
    {
      key: 'sidenav.settings.reports.add',
      path: `${APP_PREFIX_PATH}/reports/add`,
      component: React.lazy(() => import('views/app-views/reports/CreateReport'))
    },
  ],
  ROLE_MANAGER: [
    {
      key: 'sidenav.settings.users',
      path: `${APP_PREFIX_PATH}/settings/users`,
      component: React.lazy(() => import('views/app-views/users/users-list'))
    },
    {
      key: 'sidenav.settings.users',
      path: `${APP_PREFIX_PATH}/settings/users/add`,
      component: React.lazy(() => import('views/app-views/users/users-add'))
    },
    {
      key: 'sidenav.settings.users',
      path: `${APP_PREFIX_PATH}/settings/users/edit/:id`,
      component: React.lazy(() => import('views/app-views/users/users-add'))
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser`,
      component: React.lazy(() =>
        import('views/app-views/advertiser/advertiser-views')
      )
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser/add`,
      component: React.lazy(() =>
        import('views/app-views/advertiser/advertiser-add')
      )
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser/edit/:id`,
      component: React.lazy(() =>
        import('views/app-views/advertiser/advertiser-add')
      )
    },
    {
      key: 'sidenav.settings.publisher',
      path: `${APP_PREFIX_PATH}/publisher`,
      component: React.lazy(() =>
        import('views/app-views/publisher/publisher-views')
      )
    },
    {
      key: 'sidenav.settings.publisher',
      path: `${APP_PREFIX_PATH}/publisher/add`,
      component: React.lazy(() =>
        import('views/app-views/publisher/publisher-add')
      )
    },
    {
      key: 'sidenav.settings.publisher',
      path: `${APP_PREFIX_PATH}/publisher/edit/:id`,
      component: React.lazy(() =>
        import('views/app-views/publisher/publisher-add')
      )
    },
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-list')
      )
    },
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/add`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-add')
      )
    },
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/edit/:id`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-add')
      )
    },
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/:advertiserId/:id/overview`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campgins-overview')
      )
    },

    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/edit/:id/supplier/:supplierId`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-supplier')
      )
    },
    {
      key: 'sidenav.settings.forms',
      path: `${APP_PREFIX_PATH}/forms`,
      component: React.lazy(() => import('views/app-views/forms/forms-list'))
    },
    {
      key: 'sidenav.settings.forms',
      path: `${APP_PREFIX_PATH}/forms/add`,
      component: React.lazy(() => import('views/app-views/forms/forms-add'))
    },
    {
      key: 'sidenav.settings.forms',
      path: `${APP_PREFIX_PATH}/forms/edit/:id`,
      component: React.lazy(() => import('views/app-views/forms/forms-add'))
    },
    {
      key: 'sidenav.settings.profile',
      path: `${APP_PREFIX_PATH}/settings/profile/:id`,
      component: React.lazy(() =>
        import('views/app-views/profile/profile-views')
      )
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser/:id/list-campaigns`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-list')
      )
    },
    {
      key: 'sidenav.settings.publisher',
      path: `${APP_PREFIX_PATH}/publisher/:id/list-campaigns`,
      component: React.lazy(() =>
        import('views/app-views/publisher/campgin-view')
      )
    },
    {
      key: 'sidenav.settings.dashboards',
      path: `${APP_PREFIX_PATH}/dashboard`,
      component: React.lazy(() => import('views/app-views/dashboards/home'))
    },
    {
      key: 'sidenav.settings.dashboards',
      path: `/`,
      component: React.lazy(() => import('views/app-views/dashboards/home'))
    },
    {
      key: 'sidenav.settings.reports',
      path: `${APP_PREFIX_PATH}/reports`,
      component: React.lazy(() => import('views/app-views/reports/Reports'))
    },
    {
      key: 'sidenav.settings.reports.edit',
      path: `${APP_PREFIX_PATH}/reports/edit/:reportId`,
      component: React.lazy(() => import('views/app-views/reports/CreateReport'))
    },
    {
      key: 'sidenav.settings.reports.add',
      path: `${APP_PREFIX_PATH}/reports/add`,
      component: React.lazy(() => import('views/app-views/reports/CreateReport'))
    },
    {
      key: 'forms-slug',
      path: `${AUTH_PREFIX_PATH}/forms/:id`,
      component: React.lazy(() => import('views/auth-views/forms'))
    }
  ],
  ROLE_PUBLISHER: [
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/edit/:id/supplier/:supplierId`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-supplier')
      )
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser/:id/list-campaigns`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campaigns-list')
      )
    },
    {
      key: 'sidenav.settings.campaign',
      path: `${APP_PREFIX_PATH}/campaign/:advertiserId/:id/overview`,
      component: React.lazy(() =>
        import('views/app-views/campaigns/campgins-overview')
      )
    },
    {
      key: 'sidenav.settings.advertiser',
      path: `${APP_PREFIX_PATH}/advertiser`,
      component: React.lazy(() =>
        import('views/app-views/advertiser/advertiser-views')
      )
    }, {
      key: 'sidenav.settings.reports',
      path: `${APP_PREFIX_PATH}/reports`,
      component: React.lazy(() => import('views/app-views/reports/Reports'))
    },
    {
      key: 'sidenav.settings.reports.edit',
      path: `${APP_PREFIX_PATH}/reports/edit/:reportId`,
      component: React.lazy(() => import('views/app-views/reports/CreateReport'))
    },
    {
      key: 'sidenav.settings.reports.add',
      path: `${APP_PREFIX_PATH}/reports/add`,
      component: React.lazy(() => import('views/app-views/reports/CreateReport'))
    }, {
      key: 'sidenav.settings.publisher',
      path: `${APP_PREFIX_PATH}/publisher`,
      component: React.lazy(() =>
        import('views/app-views/publisher/publisher-views')
      )
    },
    {
      key: 'sidenav.settings.publisher',
      path: `${APP_PREFIX_PATH}/publisher/add`,
      component: React.lazy(() =>
        import('views/app-views/publisher/publisher-add')
      )
    },
    {
      key: 'sidenav.settings.publisher',
      path: `${APP_PREFIX_PATH}/publisher/edit/:id`,
      component: React.lazy(() =>
        import('views/app-views/publisher/publisher-add')
      )
    }, {
      key: 'sidenav.settings.dashboards',
      path: `${APP_PREFIX_PATH}/dashboard`,
      component: React.lazy(() => import('views/app-views/dashboards/home'))
    },
    {
      key: 'sidenav.settings.dashboards',
      path: `/`,
      component: React.lazy(() => import('views/app-views/dashboards/home'))
    },
  ]
};
