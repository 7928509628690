import { ROLES } from 'constants/UserRoleConstant';
import _ from 'lodash';
import axiosInstance from 'utils/axios';

const baseUrl = '/convey_ai/v1/campaign';

const campService = {};

campService.saveCamp = function (data) {
  if (data.id) {
    return axiosInstance.put(`${baseUrl}/${data.id}`, data);
  } else {
    return axiosInstance.post(`${baseUrl}/save`, data);
  }
};
campService.pauseCampagin = function (accountId, id) {
  return axiosInstance.put(`${baseUrl}/${accountId}/pauseCampaign/${id}`);
};

campService.resumeCampaign = function (accountId, id) {
  return axiosInstance.put(`${baseUrl}/${accountId}/resumeCampaign/${id}`);
};

campService.deleteSupplier = function (data) {
  return axiosInstance.delete(
    `${baseUrl}/${data.accountId}/${data.campId}/deleteSupplier/${data.id}`
  );
};

campService.updateSupplier = function (data) {
  return axiosInstance.put(`${baseUrl}/${data.accountID}/updateSupplier`, data);
};

campService.getAllCamps = function (data, advId) {
  return axiosInstance.get(`${baseUrl}/getall/${data}/${advId}`);
};
campService.getCampDetails = (data, advId, role, email) => {
  if (_.includes(role, ROLES.ROLE_MANAGER) || _.isEqual(role, ROLES.ROLE_SYSTEM_ADMIN)) {
    return axiosInstance.get(`${baseUrl}/getallDetails/${data}/${advId}`);
  } else if (_.includes(role, ROLES.ROLE_ADVERTISER)) {
    return axiosInstance.get(`${baseUrl}/getallDetails/${data}/advertiser/${advId}/${email}`);
  } else if (_.includes(role, ROLES.ROLE_PUBLISHER)) {
    return axiosInstance.get(`${baseUrl}/getallDetails/${data}/publisher/${advId}/${email}`);
  }
}


campService.getCamps = (accountId, role, publisherId) => {
  if (_.includes(role, ROLES.ROLE_MANAGER) || _.isEqual(role, ROLES.ROLE_SYSTEM_ADMIN) || _.includes(role, ROLES.ROLE_ADVERTISER)) {
    return axiosInstance.get(`${baseUrl}/all/${accountId}`);
  } else if (_.includes(role, ROLES.ROLE_PUBLISHER)) {
    return axiosInstance.get(`${baseUrl}/all/${accountId}/publisher/${publisherId}`);
  }
}

campService.deleteCamps = function (data) {
  return axiosInstance.delete(`${baseUrl}/${data.accountId}/${data.id}`);
};

campService.createSupplier = function (data) {
  return axiosInstance.post(
    `${baseUrl}/${data.accountID}/createSupplier`,
    data
  );
};
campService.getCampById = function (accountId, cmpId) {
  if (_.isEmpty(cmpId)) {
    return axiosInstance.get(`${baseUrl}/${accountId?.accountId}/${accountId?.cmpId}`);
  } else {
    return axiosInstance.get(`${baseUrl}/${accountId}/${cmpId}`);
  }

};

campService.gtTrackingUrl = function (accountId, campId, publisherId) {
  return axiosInstance.get(
    `${baseUrl}/trackingLinks/${accountId}/${campId}/${publisherId}`
  );
};

campService.getPublishers = function (data) {
  return axiosInstance.get(`${baseUrl}/${data.accountId}/${data.id}/list`);
};

campService.getPublisherDetails = (data, role, gmail) => {
  if (_.includes(role, ROLES.ROLE_MANAGER) || _.isEqual(role, ROLES.ROLE_SYSTEM_ADMIN)) {
    return axiosInstance.get(`${baseUrl}/${data.accountId}/${data.id}/listDetails`);
  } else if (_.includes(role, ROLES.ROLE_PUBLISHER)) {
    return axiosInstance.get(`${baseUrl}/${data.accountId}/${data.id}/listDetails?gmail=${gmail}`);
  }
}

campService.updateTerms = function (data) {
  return axiosInstance.put(
    `${baseUrl}/${data.accountID}/supplier/editTerms`,
    data
  );
};

//accountId,campId,publisherId

campService.getTerms = function (accountId, campId, publisherId) {
  return axiosInstance.get(
    `${baseUrl}/public/terms?accountId=${accountId}&campId=${campId}&publisherId=${publisherId}`
  );
};

campService.signContract = function (data) {
  return axiosInstance.post(`${baseUrl}/public/signContracts`, data);
};

campService.rejectContract = function (data) {
  return axiosInstance.get(`${baseUrl}/public/reject/${data?.accountId}/${data?.campId}/${data?.publisherId}`);
};

campService.getLoadFields = function (accountId, id) {
  return axiosInstance.get(`${baseUrl}/${accountId}/${id}/formFields`);
};

campService.getLoadFieldsBySupplier = function (accountId, id, supplierId) {
  return axiosInstance.get(`${baseUrl}/${accountId}/${id}/formFields/supplier/${supplierId}`);
};

campService.updateJobs = function (data) {
  return axiosInstance.put(
    `${baseUrl}/${data.accountID}/${data.campaignId}/updateJobs`,
    data
  );
};

campService.updateIntergations = function (data) {
  return axiosInstance.put(
    `${baseUrl}/${data.accountID}/${data.campaignId}/updateIntegrations`,
    data
  );
};

campService.getAllLandingPages = function (data) {
  return axiosInstance.get(
    `/convey_ai/v1/landingPage/${data.accountId}/${data.campaignId}/`,
    data
  );
};

export default campService;
