import {
  ALL_LANDING_PAGES,
  DELETE_LANDING_PAGE,
  UPADTE_ALL_LANDING_PAGES,
  GET_LANDING_PAGE,
  UPDATE_LANDING_PAGE
} from 'redux/actions/LandingPageAction';
import campService from 'services/campService';
import accountService from 'services/accountService';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { message } from 'antd';
function* getAllLandingPagesAction(action) {
  //const projects = yield projectService.getAllProjects();
  const { data } = yield campService.getAllLandingPages(action.payload);

  yield put({
    type: UPADTE_ALL_LANDING_PAGES,
    payload: data
  });
}

function* deleteLandingPageAction(action) {
  const data = yield accountService.deleteLandingPage(action.payload);
  if (data.status === 204) {
    message.success('Account delete successfully');
    const { finalData } = yield accountService.getAllAccounnts();
    yield put({
      type: UPADTE_ALL_LANDING_PAGES,
      payload: finalData
    });
  }
}

export function* fetchAllLandingPages() {
  yield takeLatest(ALL_LANDING_PAGES, getAllLandingPagesAction);
}

export function* deleteLandingPage() {
  yield takeLatest(DELETE_LANDING_PAGE, deleteLandingPageAction);
}
