import { all } from 'redux-saga/effects';
import { login } from './authSaga';
import { fetchAllAccounts, deleteAccount, getAccount } from './accountSaga';
import { fetchAllUsers, deleteUser, updateUser } from './userSaga';

import {
  fetchAllAdvertiser,
  deleteAdvertiser,
  getAdvertiser
} from './advertiserSaga';

import {
  fetchAllPublisher,
  deletePublisher,
  getPublisher
} from './publisherSaga';

import { fetchAllForms, deleteForms } from './formSaga';

import { fetchAllCamps, deleteCamp, getCamp } from './campSaga';

import { fetchAllLandingPages, deleteLandingPage } from './landingPageSaga';

export default function* rootSaga() {
  yield all([
    login(),
    fetchAllAccounts(),
    deleteAccount(),
    fetchAllUsers(),
    deleteUser(),
    updateUser(),
    fetchAllAdvertiser(),
    deleteAdvertiser(),
    getAdvertiser(),
    fetchAllPublisher(),
    deletePublisher(),
    getPublisher(),
    fetchAllCamps(),
    deleteCamp(),
    getCamp(),
    fetchAllForms(),
    deleteForms(),
    getAccount(),
    fetchAllLandingPages(),
    deleteLandingPage()
  ]);
}
