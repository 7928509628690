import {
  UPDATE_FORM,
  UPADTE_ALL_FORMS,
  NEW_FORM
} from '../actions/FormActions';

const initialState = {
  formData: {},
  forms: [],
  mode: 'ADD'
};

const formReducer = (state = initialState, action) => {
  console.log(action.type);
  if (action.type === UPDATE_FORM) {
    return Object.assign({}, state, {
      formData: action.payload
    });
  }
  if (action.type === UPADTE_ALL_FORMS) {
    return Object.assign({}, state, {
      forms: action.payload
    });
  }
  if (action.type === NEW_FORM) {
    return Object.assign({}, state, {
      formData: {}
    });
  }
  return state;
};

export default formReducer;
