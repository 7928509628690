import {
  UPADTE_USER,
  UPADTE_ALL_USERS,
  NEW_USERS
} from '../actions/UserAction';

const initialState = {
  formData: {},
  accounts: [],
  mode: 'ADD'
};

const userReducer = (state = initialState, action) => {
  console.log(action.type);
  if (action.type === UPADTE_USER) {
    return Object.assign({}, state, {
      formData: action.payload
    });
  }

  if (action.type === UPADTE_ALL_USERS) {
    return Object.assign({}, state, {
      accounts: action.payload
    });
  }
  if (action.type === NEW_USERS) {
    return Object.assign({}, state, {
      formData: {}
    });
  }
  return state;
};

export default userReducer;
