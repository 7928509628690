export const UPADTE_USER = 'UPADTE_USER';
export const ALL_USERS = 'ALL_USERS';
export const UPADTE_ALL_USERS = 'UPADTE_ALL_USERS';
export const NEW_USERS = 'NEW_USERS';
export const DELETE_USERS = 'DELETE_USERS';
export const GET_USER = 'GET_USER';

export function getAllUsers(action) {
  return {
    type: ALL_USERS,
    payload: action
  };
}

export function newUsers() {
  return {
    type: NEW_USERS
  };
}

export function updateUser(action) {
  return {
    type: UPADTE_USER,
    payload: action
  };
}

export function deleteUser(action) {
  return {
    type: DELETE_USERS,
    payload: action
  };
}

export function getUser(action) {
  return {
    type: GET_USER,
    payload: action
  };
}
