import { AUTH_TOKEN } from 'constants/AuthConstant';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import FirebaseService from 'services/FirebaseService';
import * as ActionTypes from '../actions/LoginAction';

function* loginAction(action) {
  //const projects = yield projectService.getAllProjects();
  const { email, password } = action;
  const { user } = yield FirebaseService.signInEmailRequest(email, password);
  localStorage.setItem(AUTH_TOKEN, user.accessToken);

  yield put({
    type: ActionTypes.UPDATE_LOGIN_INFO,
    payload: user
  });
}

export function* login() {
  yield takeLatest(ActionTypes.LOGIN, loginAction);
}
