import {
  UPADTE_LANDING_PAGE,
  UPADTE_ALL_LANDING_PAGES,
  NEW_LANDING_PAGE
} from '../actions/LandingPageAction';

const initialState = {
  formData: {},
  landingPages: [],
  mode: 'ADD'
};

const landingPageReducer = (state = initialState, action) => {
  if (action.type === UPADTE_LANDING_PAGE) {
    return Object.assign({}, state, {
      formData: action.payload
    });
  }
  if (action.type === UPADTE_ALL_LANDING_PAGES) {
    return Object.assign({}, state, {
      landingPages: action.payload
    });
  }
  if (action.type === NEW_LANDING_PAGE) {
    return Object.assign({}, state, {
      formData: {}
    });
  }
  return state;
};

export default landingPageReducer;
