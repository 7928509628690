import axios from 'axios';
import { User } from 'oidc-client-ts';
// config

// ----------------------------------------------------------------------

function getUser() {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:http://192.168.1.57:8080/realms/Coney_ai:convey_ai`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

const axiosInstance = axios.create({ baseURL: 'https://stageapi.convey.ai' });
axiosInstance.interceptors.request.use(headerConfig => {
  const token = localStorage.getItem('auth_token');
  if (token) {
    headerConfig.headers.Authorization = `Bearer ${token}`;
  }
  return headerConfig;
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('auth_token');
      window.location.reload();
    } else if (error.response == undefined) {
      // window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);
export default axiosInstance;
