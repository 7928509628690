import {
  UPDATE_CAMP,
  UPDATE_ALL_CAMPS,
  NEW_CAMP,
  ADD_FIELDS,
  ADD_SUPPLIER_FORM_DATA
} from '../actions/CampActions';

const initialState = {
  formData: {},
  supplierFormData: {},
  camps: [],
  mode: 'ADD',
  fields: []
};

const campReducer = (state = initialState, action) => {
  console.log(action.type);
  if (action.type === UPDATE_CAMP) {
    return Object.assign({}, state, {
      formData: action.payload
    });
  }
  if (action.type === ADD_FIELDS) {
    return Object.assign({}, state, {
      fields: action.payload
    });
  }
  if (action.type === ADD_SUPPLIER_FORM_DATA) {
    return Object.assign({}, state, {
      supplierFormData: action.payload
    });
  }
  if (action.type === UPDATE_ALL_CAMPS) {
    return Object.assign({}, state, {
      camps: action.payload
    });
  }
  if (action.type === NEW_CAMP) {
    return Object.assign({}, state, {
      formData: {}
    });
  }
  return state;
};

export default campReducer;
