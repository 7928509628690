export const UPDATE_FORM = 'UPDATE_FORM';
export const ALL_FORMS = 'ALL_FORMS';
export const UPADTE_ALL_FORMS = 'UPADTE_ALL_FORMS';
export const NEW_FORM = 'NEW_FORM';
export const DELETE_FORM = 'DELETE_FORM';

export function getAllForms(payload) {
  return {
    type: ALL_FORMS,
    payload: payload
  };
}

export function newForm() {
  return {
    type: NEW_FORM
  };
}

export function updateForm(action) {
  return {
    type: UPDATE_FORM,
    payload: action
  };
}

export function deleteForm(action) {
  return {
    type: DELETE_FORM,
    payload: action
  };
}
