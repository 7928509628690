import {
  ALL_USERS,
  DELETE_USERS,
  GET_USER,
  UPADTE_ALL_USERS,
  UPADTE_USER,
  UPDATE_USER_FORM
} from 'redux/actions/UserAction';
import userService from 'services/userService';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { message } from 'antd';

function* getAllUsersActions(action) {
  let finalData = {};

  if (action.payload.role.indexOf('ROLE_SYSTEM_ADMIN') !== -1) {
    const { data } = yield userService.getAllUsers();
    finalData = data;
  }

  if (action.payload.role.indexOf('ROLE_MANAGER') !== -1) {
    const { data } = yield userService.getUserByAccount(
      action.payload.accountId
    );
    finalData = data;
  }

  yield put({
    type: UPADTE_ALL_USERS,
    payload: finalData
  });
}

function* deleteUserAction(action) {
  const data = yield userService.deleteUser(action.payload.id);
  if (data.status === 204) {
    message.success('Account delete successfully');
    const { role } = action.payload;
    if (role.indexOf('ROLE_SYSTEM_ADMIN') != -1) {
      const { finalData } = yield userService.getAllUsers();
      yield put({
        type: UPADTE_ALL_USERS,
        payload: finalData
      });
    } else {
      // const { accountId } = action.payload;

      yield put({
        type: ALL_USERS,
        payload: action.payload
      });
    }
  }
}

function* getUser(action) {
  const { data } = yield userService.getUser(action.payload);
  yield put({
    type: UPADTE_USER,
    payload: data
  });
}

export function* fetchAllUsers() {
  yield takeLatest(ALL_USERS, getAllUsersActions);
}

export function* deleteUser() {
  yield takeLatest(DELETE_USERS, deleteUserAction);
}

export function* updateUser() {
  yield takeLatest(GET_USER, getUser);
}
