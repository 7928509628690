import {
  ALL_PUBLISHER,
  DELETE_PUBLISHER,
  GET_PUBLISHER,
  UPADTE_ALL_PUBLISHER,
  UPDATE_PUBLISHER
} from 'redux/actions/publisherAction';
import publisherService from 'services/publisherService';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { message } from 'antd';
function* getAllPublisherAction(action) {
  //const projects = yield projectService.getAllProjects();
  let finalData = {};

  const { data } = yield publisherService.getByAccount(
    action.payload.accountId
  );
  finalData = data;

  yield put({
    type: UPADTE_ALL_PUBLISHER,
    payload: finalData
  });
}

function* deletePublisherAction(action) {
  const data = yield publisherService.deletePublisher(
    action.payload.id,
    action.payload.accountId
  );
  if (data.status === 204) {
    message.success('Publisher deleted successfully');
    const { finalData } = yield publisherService.getByAccount(
      action.payload.accountId
    );
    yield put({
      type: UPADTE_ALL_PUBLISHER,
      payload: finalData
    });
  }
}

function* getPublisherAction(action) {
  //const projects = yield projectService.getAllProjects();
  let finalData = {};

  const { data } = yield publisherService.getPublisher(
    action.payload.accountId,
    action.payload.id
  );
  finalData = data;
  if (finalData.autoSignContracts) {
    finalData['autoSignContracts'] = finalData.autoSignContracts ? 'T' : 'F';
  }
  yield put({
    type: UPDATE_PUBLISHER,
    payload: finalData
  });
}

export function* fetchAllPublisher() {
  yield takeLatest(ALL_PUBLISHER, getAllPublisherAction);
}

export function* deletePublisher() {
  yield takeLatest(DELETE_PUBLISHER, deletePublisherAction);
}

export function* getPublisher() {
  yield takeLatest(GET_PUBLISHER, getPublisherAction);
}
